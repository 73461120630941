.navbar {
    border-bottom: 0.063rem solid $grey;

    &-brand {
        width: 6.371rem;
        height: 5rem;
        margin-bottom: 0.375rem;
    }

    .nav-button,
    &-nav .nav-link,
    .dropdown-item {
        color: $blue-primary !important;
        font: {
            size: 0.875rem !important;
            weight: 400 !important;
        }
        line-height: 1.25rem;
    }

    .dropdown {
        .nav-button {
            width: 13rem;

            .nav-button-icon {
                width: 2rem;
                height: 2rem;
            }
        }

        &-menu {
            border-color: $grey;
        }
    }

    &-toggler,
    .nav-button {
        height: 2.9rem;

        &:focus {
            box-shadow: none;
        }
    }

    .nav-button {
        border-color: $grey !important;
        width: 8rem;
        padding: 1rem 1rem 1rem 1rem;
        gap: 0.25rem;
        border-radius: 0.25rem;
        border: 0.063rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &-icon {
            color: $blue-primary;
            height: 0.875rem;
            width: 0.875rem;
        }

        &-cart {
            font-size: 0.625rem;
            color: $red-first;
            position: absolute;
            top: 0.313rem;
            right: 1.2rem;
            font-weight: 700;
        }
    }

    .d-grid {
        grid-template-columns: 1fr 1fr;
    }

    .collapse {
        background: $blue-primary;

        .card {
            background: none;

            .nav-link {
                color: $white !important;
            }
        }
    }

    &-toggler {
        margin: {
            top: -0.5rem;
            right: -0.563rem;
        }
        width: 2.188rem;
        height: 1.313rem;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 0.25rem;
            width: 37%;
            background: $blue-primary;
            opacity: 1;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;

            &:nth-child(even) {
                left: 36%;
                border-radius: 0 0.563rem 0.563rem 0;
            }

            &:nth-child(odd) {
                left: 0px;
                border-radius: 0.563rem 0 0 0.563rem;
            }

            &:nth-child(1),
            &:nth-child(2) {
                top: 0px;
            }

            &:nth-child(3),
            &:nth-child(4) {
                top: 0.5rem;
            }

            &:nth-child(5),
            &:nth-child(6) {
                top: 1rem;
            }
        }

        &.open span:nth-child(1),
        &.open span:nth-child(6) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &.open span:nth-child(2),
        &.open span:nth-child(5) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &.open span:nth-child(1),
        &.open span:nth-child(2) {
            top: 0.438rem;
        }

        &.open span:nth-child(1) {
            left: 0.313rem;
        }

        &.open span:nth-child(2),
        &.open span:nth-child(6) {
            left: calc(50% - 5px);
        }

        &.open span:nth-child(3) {
            left: -50%;
            opacity: 0;
        }

        &.open span:nth-child(4) {
            left: 100%;
            opacity: 0;
        }

        &.open span:nth-child(5),
        &.open span:nth-child(6) {
            top: 0.938rem;
        }

        &.open span:nth-child(5) {
            left: 0.313rem;
        }
    }
}

@media (max-width: $phone-md) {
    .navbar-brand {
        width: 4rem;
        margin: 0;
    }
}

@media (max-width: $tablet) {
    .navbar {
        .nav-button {
            width: auto;

            &-cart {
                right: 0.5rem;
            }
        }

        .dropdown-menu {
            left: -6rem;
        }

        .dropdown {
            .nav-button {
                width: auto;
            }
        }
    }
}

@media (min-width: $desktop-x) {
    .navbar {
        .w-lg-100 {
            width: 100%;
        }
    }
}
