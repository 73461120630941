.datepicker {
    width: fit-content !important;

    &--cell.-enabled-.-current-,
    &--nav-title,
    &--nav-title i {
        color: $blue-primary !important;
    }

    &--cell.-selected- {
        background-color: $blue-primary !important;
    }

    &--nav-action,
    &--cell.-weekend-.-selected-,
    &--cell.-enabled-.-selected-,
    &--cell.-enabled-.-selected-.-focus-,
    &--cell.-selected- {
        background-color: $blue-primary;
    }

    &--cell.-enabled-.-current-.-selected-,
    &--cell.-selected-.-focus- {
        background-color: $blue-primary;
        color: $white !important;
    }

    &--cell.-enabled-,
    &--cell.-other-month-.-enabled-,
    &--cell.-current- {
        color: $orange-fist;

        &:hover {
            color: $white !important;
            background-color: $blue-primary;
        }
    }

    &--cell-year.-other-decade- {
        background-color: $white;
        // color: $grey-eith;
    }

    &--cell-day,
    &--cell.-weekend-.-disabled- {
        background-color: $white;
        // color: $grey-eith;
    }

    &--cell.-focus-,
    &--cell.-selected- {
        background-color: $orange-fist;
        color: $white;
    }
}
