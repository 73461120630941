.footer {
    border-top: .063rem solid $grey;

    &-title {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.75rem;
        color: $blue-primary;
    }

    .nav {
        &-link {
            width: calc(100% / 4);
            font-size: .75rem;
            font-weight: 400;
            line-height: 1rem;
            color: $grey-fourth;
        }
    }

    &-text {
        background: $blue-primary;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.25rem;
    }
}

@media(max-width: $tablet) {
    .footer {
        .nav-link {
            width: 100%;
        }
    }
}

@media(max-width: $desktop) {
    .footer {
        .nav-link {
            width: calc(100% / 2 - 5px);
        }
    }
}
