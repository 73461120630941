.breadcrumb {
    &-item {
        font: {
            size: .875rem;
            weight: 400;
        }
        line-height: 1.25rem;

        a {
            color: $grey-third;
            text-decoration: none;
        }

        .active {
            color: $blue-primary;
        }
    }
}
