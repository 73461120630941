.range_container {
    display: flex;
    flex-direction: column;

    .sliders_control {
        position: relative;
    }

    .form_control {
        position: relative;
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        color: #635a5a;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        pointer-events: all;
        width: 1rem;
        height: 1rem;
        background-color: $orange-second;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $orange-second;
        cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        pointer-events: all;
        width: 1rem;
        height: 1rem;
        background-color: $orange-second;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $orange-second;
        cursor: pointer;
    }

    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        height: 0.25rem;
        width: 100%;
        position: absolute;
        background-color: $grey;
        pointer-events: none;

        &:nth-child(2) {
            background: transparent !important;
        }
    }

    #fromSlider {
        height: 0;
        z-index: 1;
    }
}
