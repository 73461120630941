.filter {
    label {
        color: $black-second;
        line-height: 1.5rem;
        font: {
            weight: 700;
            size: 1rem;
        }
    }

    &-text {
        line-height: 1rem;
        color: $grey-third;
        font: {
            size: 0.75rem;
            weight: 400;
        }
    }

    .form-control {
        border-radius: 0.5rem;
        border: 0.125rem solid $grey-nine;
        height: 3.25rem;
        border-width: 0.063rem;
        color: $grey-six;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;

        &:focus {
            box-shadow: none;
        }

        &::placeholder,
        &::-ms-input-placeholder,
        &::-moz-input-placeholder,
        &::-o-input-placeholder {
            color: $grey-six;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
        }

        &-icon {
            right: 0.8rem;
            width: 1.2rem;
            height: 1.5rem;
            color: $blue-primary;
            font-weight: 700;
        }
    }

    .form-check {
        &-input {
            width: 1.938rem;
            height: 1.938rem;
            border-radius: 0.5rem;
            border: 0.125rem solid $grey-sencond;
            // background: $grey-fifth;

            &:focus {
                box-shadow: none;
            }

            &:active {
                background: none;
            }
        }

        &-label {
            color: $black-third;
            line-height: 1.25rem;
            font: {
                size: 0.875rem;
                weight: 400;
            }
        }
    }
}
