.products {
    a {
        text-decoration: none;
        color: initial;
    }

    &-cardv1 {
        .card {
            &-title {
                font-size: 1rem;
                line-height: 1.5rem;
            }

            &-subtitle {
                line-height: 1rem;
                background: $orange-second;
                height: 1.5rem;
            }

            &-amount {
                font-size: 1.75rem;
                font-weight: 700;
                line-height: 2.25rem;
                color: $orange-second;
            }

            &-img-top {
                height: 15rem;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }

            &-text,
            &-subtitle {
                font-size: 0.75rem;
                line-height: 1rem;
            }

            &-text {
                font-weight: 400;
                color: $grey-third;
            }
        }
    }

    &-cardv1-title,
    .card-title {
        color: $blue-primary;
        font-weight: 700;
    }

    .card {
        border-radius: 1rem;
        border: 0.063rem solid $grey;

        &-title {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        &-link {
            outline: none;
            text-decoration: none;
        }
    }

    &-cardv2 {
        @extend .products-cardv1;
        .card {
            &-img-top {
                max-width: 21.375rem;
                min-height: 15rem;
                height: auto;
                border-top-right-radius: 0;
                border-bottom-left-radius: 1rem;
                object-fit: fill;
            }

            &-link {
                color: $blue-primary;
            }

            &-text {
                color: $grey-third;
            }

            &-footer {
                background: $white;
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
                border-bottom-left-radius: 1rem;
            }

            &-btn {
                width: 13.813rem;
                height: 2.25rem;
                padding: 0.5rem 1rem 0.5rem 1rem;
                gap: 0.25rem;
                border-radius: 0.25rem;
                color: $white;
                background: $orange-second;
                line-height: 1.25;
                font: {
                    size: 0.875rem;
                    weight: 400;
                }
            }
        }
    }
}

@media (max-width: $desktop-x) {
    .products-cardv2 {
        .card {
            &-img-top {
                max-width: none;
                border-top-right-radius: inherit;
                border-bottom-left-radius: 0;
            }
        }
    }
}
