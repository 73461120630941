.cart {
    &-table {
        thead {
            th {
                background: $white-secondy;
                padding: 1rem;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.5rem;
                border: 0.063rem solid $white;

                &:nth-child(3) {
                    width: 33%;
                }
            }
        }

        tbody {
            a,
            td {
                padding: 0.9rem;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.25rem;
                color: $blue-primary;
            }

            td {
                &:nth-child(2) {
                    color: $grey-seven;
                }
            }
        }

        &-option {
            &-item {
                .feather {
                    width: 1.25rem;
                }

                .icon-success {
                    color: $green-first;
                }
            }
        }

        &-subtitle {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            color: $grey-third;
        }
    }

    &-card {
        .card {
            &-title {
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.75rem;
                color: $blue-primary;
            }

            &-subtitle {
                font-size: 0.9rem;
            }

            &-link {
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.25rem;
                color: $blue-primary;
            }
        }

        .btn-icon {
            color: $blue-primary;
            font-size: 0.75rem;

            .text-primary {
                color: $blue-primary !important;
                width: 1rem;
            }
        }

        .form-select {
            font-size: 0.75rem;
        }

        .nav-link {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25rem;
            color: $grey-third;

            .feather {
                color: $orange-second;
            }

            &:hover,
            &.active {
                color: $blue-primary;
            }
        }
    }

    &-form {
        label {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            color: $black-second;
        }

        .form-control {
            height: 3.25rem;
            padding: 1rem;
            gap: 0.25rem;
            border-radius: 0.25rem;

            &:focus {
                box-shadow: none;
            }
        }
    }
}
