.comment {
    &-title {
        @extend .page-title;
        font-size: 1.25rem;
    }

    &-badge {
        background: $orange-second;
        height: 1.75rem;
        padding: .125rem .5rem .125rem .5rem;
        border-radius: 3.125rem;
        font-size: .75rem;
        font-weight: 700;
        line-height: 1.5rem;
    }

    &-card {
        .card {
            border-color: $grey;
            border-radius: 1rem;

            &-header,
            &-body {
                border-radius: 1rem;
            }

            &-title {
                color: $black-primary;
                font-weight: 700;
            }

            &-title,
            &-text {
                font-size: 1rem;
                line-height: 1.5rem;
            }

            &-text {
                color: $grey-third;
                font-weight: 400;
            }
        }
    }
}
