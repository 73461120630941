.page {
    &-title {
        line-height: 2.25rem;
        font-size: 1.75rem;
    }

    &-step {
        max-width: 720px;
    }

    &-step-done {
        line-height: 1.8rem;
        font-size: 1rem;
        font-weight: bold;
        color: $blue-primary;
    }

    &-step-todo {
        line-height: 1.8rem;
        font-size: 1rem;
        font-weight: bold;
        color: #aaa;
    }

    &-blue {
        background-color: $blue-primary;
        color: #fff;
    }

    &-gray {
        background-color: #aaa;
        color: #fff;
    }

    &-circle {
        height: 1.25rem;
        width: 1.25rem;
        font-size: 0.75rem !important;
        font-weight: 700;
        border-radius: 50%;
    }

    &-center {
        align-items: center;
        justify-content: center;
        display: flex;
    }

    &-title,
    &-subtitle {
        color: $blue-primary;
        font-weight: 700;
    }

    &-spinner {
        strong {
            color: $blue-primary;
            font-weight: 700;
        }

        .spinner-border {
            border-color: $blue-primary;
            border-right-color: $white;
        }
    }

    &-subtitle {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    &-text {
        color: $grey-third;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;

        a,
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h5,
        li {
            color: $grey-third;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5rem;
        }

        p {
            margin: 0;
        }

        iframe {
            width: 100%;
            max-height: 28rem;
        }
    }

    &-select {
        max-width: 15rem;
        width: 100%;
        height: 3.25rem;
        padding: 1rem;
        padding-right: 1.625rem;
        gap: 0.25rem;
        border-radius: 0.25rem;
        border: 0.063rem;
        color: $grey-six;
        border: 0.063rem solid $grey;
        line-height: 1.25rem;
        font: {
            size: 0.875rem;
            weight: 400;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        -ms-appearance: none !important;
        -o-appearance: none !important;
        appearance: none !important;
        background: {
            image: url("/assets/images/chevron-down.svg");
            repeat: no-repeat !important;
            position-x: 94% !important;
            position-y: 50% !important;
        }
    }

    &-paginate {
        .small {
            display: none;
        }

        .pagination {
            margin: 0;

            .page-item {
                padding: 0 0.625rem;

                &:first-child,
                &:last-child {
                    display: none;
                }

                &.active > .page-link {
                    color: $blue-primary;
                }

                &.disabled > .page-link {
                    border: none;
                }

                &.active,
                &.disabled {
                    > .page-link {
                        background-color: $white;
                    }
                }

                &.active > .page-link:hover {
                    background-color: $blue-primary;
                    border-color: $blue-primary;
                    color: $white;
                }
            }

            .page-link {
                width: 4rem;
                height: 4rem;
                gap: 0px;
                border-radius: 100%;
                border: 0.063rem solid $grey;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.5rem;
                color: $grey-third;

                &:hover {
                    background-color: $orange-second;
                    border-color: $orange-second;
                    color: $white;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    &-content {
        background: $blue-primary;

        &-card.card {
            background-color: transparent;
        }

        .card-img-top {
            width: 4rem;
        }

        .card-title {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5rem;
        }

        .card-text {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
        }

        .card-body {
            width: 83%;
        }
    }

    &-slide {
        &-image img {
            min-width: 37.5rem;
            height: 25rem;
            object-fit: cover;
        }
    }

    &-search {
        background: $blue-primary;
        border-radius: 1rem;

        &-title,
        &-subtitle,
        &-amount,
        &-form label,
        &-form-button {
            color: $white;
        }

        &-title {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.75rem;
        }

        &-subtitle {
            font-size: 0.875rem;
            font-weight: 500;
        }

        &-amount {
            margin-top: 0.2rem;
            font-size: 1.75rem;
            font-weight: 700;
            line-height: 2.25rem;
        }

        &-form label,
        &-subtitle {
            line-height: 1.25rem;
        }

        &-form {
            &-button,
            label {
                font-size: 0.875rem;
                font-weight: 400;
            }

            &-button,
            .form-control {
                height: 3.25rem;
                border-radius: 0.25rem;
            }

            &-button {
                line-height: 1.25rem;
                background: $orange-second;

                &:hover {
                    background: $orange-second;
                    border-color: $white;
                    color: $white;
                }
            }
        }

        &-detail {
            &-title {
                font-weight: 700;
            }

            &-title,
            &-text {
                font-size: 1rem;
                line-height: 1.5rem;
            }

            &-text {
                font-weight: 400;
            }
        }
    }

    &-company-card {
        &-img {
            width: 2.5rem;
            height: 2.5rem;
            object-fit: fill;
            object-position: center;
            border: 0.188rem solid $grey;
        }

        &-title {
            color: $blue-primary;
            font: {
                size: 0.7rem;
                weight: 400;
            }
            line-height: 1.25rem;
            text-align: center;
        }
    }

    &-modal-btn {
        font: {
            size: 0.875rem;
            weight: 400;
        }
        line-height: 1.25rem;
        height: 3.25rem;
        border-radius: 0.25rem;

        &--open,
        &--open:hover {
            border-color: $grey;
        }

        &--filter {
            background: $orange-second;
        }

        &--close {
            border-color: $blue-primary;
            color: $blue-primary;

            &:hover {
                background: $blue-primary;
                color: $white;
            }
        }
    }

    &-seller {
        &-title {
            font-size: 2.25rem;
            line-height: 2.75rem;
            font-weight: 700;
        }
    }
}

@media (min-width: $desktop-x) {
    .page-company-card .page-title {
        margin-left: -1.5rem;
    }
}

@media (max-width: $tablet) {
    .page {
        &-step {
            max-width: 720px;
        }

        &-text {
            iframe {
                max-height: 15rem;
            }
        }
    }
}
