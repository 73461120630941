.destiny {
    &-title {
        font: {
            size: 1.25rem;
            weight: 700;
        }
        line-height: 1.75rem;
        color: $black-second;
    }

    .card {
        border-radius: 1rem;
        height: 10rem;
        width: 15rem;

        &-img-top {
            border-radius: 1rem;
            height: 100%;
            object-fit: cover;
        }

        &-body {
            bottom: 0;
            background: $black-primary-gradiend;
            border-radius: 1rem;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        &-title,
        &-subtitle {
            color: $white;
        }
    }

    &-slide {
        .slick {
            &-dots {
                display: flex;
                justify-content: center;
                padding-inline-start: 0 !important;
                margin-block-start: 0;
                margin-block-end: 0;
                list-style: none;
                padding-top: 1rem;

                button {
                    opacity: 0;
                }

                li {
                    width: 0.625rem;
                    height: 0.625rem;
                    background: $grey-sencond;
                    margin: 0.125rem 0.438rem;
                    border-radius: 100%;
                    cursor: pointer;

                    &.slick-active {
                        background-color: $orange-second;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    .destiny .card {
        width: 20rem;
        height: 20rem;
    }
}
