@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import "../variable";
@import "custom";
@import "header";
@import "slide";
@import "search";
@import "destiny";
@import "products";
@import "footer";
@import "breadcrumb";
@import "filter";
@import "page";
@import "comment";
@import "cart";
@import "datepicker";
@import "inputrange";
