* {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

hr {
    height: 0.063rem !important;
    background: $grey !important;
}
.customhr {
    border: 1px solid #0000;
    width: 100px;
    height: 0.063rem;
}

.hrblue {
    background: $blue-primary !important;
    border-color: $blue-primary !important;
}

.bg-primary {
    background: $blue-primary !important;
}

.text-primary {
    color: $blue-primary !important;
}

.select2-container--default .select2-selection--single,
.select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 2.5rem !important;
}

.select2-container--default .select2-selection--single {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    border: 1px solid #dee2e6 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.select2-results__option[aria-selected],
.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    font-size: 0.9rem;
}
