.search {
    background-color: $orange-second;

    &-title {
        line-height: 1.75rem;
        width: 13rem;
        font: {
            size: 1.25rem;
            weight: 700;
        }
    }

    &-group {
        grid-template-columns: 1fr 1fr;
    }

    &-form,
    .form-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-column-gap: 1.25rem;
        grid-row-gap: 1.25rem;
        width: min-content;
    }

    .form-group {
        padding-bottom: 1.25rem;
    }

    .form-control {
        padding: 1rem;
        gap: 0.25rem;
        height: 3.25rem;
        border-radius: 0.25rem;
        background: $white;
        border: 0;
        width: 14.25rem;
    }

    .btn-primary {
        background: $blue-primary;
        border-color: $blue-primary;
        line-height: 1.5rem;
        width: max-content;
        height: 3.25rem;
        padding: 1rem 2rem 1rem 2rem;
        gap: 0.25rem;
        border-radius: 0.25rem;
        margin-left: 1.25rem;
        font : {
            size: 0.875rem;
            weight: 400;
        }
    }
}

@media (min-width: $tablet) and (max-width: $desktop) {
    .search {
        &-form {
            grid-template-columns: 1fr;
            grid-auto-flow: row;
        }

        &-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .form-group {
            grid-template-columns: 1fr 1fr;
            width: min-content;
        }

        .btn-primary {
            width: auto;
            margin-left: 1.25rem;
            font-size: 0.8rem;
        }
    }
}

@media (max-width: $tablet) {
    .search {
        &-form,
        .form-group {
            grid-template-columns: 1fr;
            grid-auto-flow: row;
            width: 100%;
        }

        .form-control,
        .form-control:first-child,
        .btn-primary {
            width: 100%;
        }

        .btn-primary {
            margin-left: 0;
        }
    }
}

@media (max-width: $desktop) {
    .search {
        &-title {
            font-size: 1rem;
        }

        &-form,
        .form-group {
            width: 100%;
        }
    }
}

@media (min-width: $desktop-x) {
    .search {
        .form-control {
            width: 11.25rem;

            &:first-child {
                width: 19.375rem;
            }
        }

        .btn-primary {
            width: 15.688rem;
        }
    }
}
