$white: #ffffff;
$white-secondy: #f5f5f5;

$grey: #e0e0e0;
$grey-sencond: #c2c2c2;
$grey-third: #616161;
$grey-fourth: #5f5f5f;
$grey-fifth: #f4f4f4;
$grey-six: #757575;
$grey-seven: #424242;
$grey-eith: #aeaeae;
$grey-nine: #d8d8d8;

$blue-primary: #008bd0;
$blue-second: #00a0fd;
$blue-primary-gradient: #008bd012;
$blue-primary-gradient: #008bd012;

$green-first: #20c997;

$orange-fist: #ffc107;
$orange-second: #fba81c;

$red-first: #dc3545;

$black-primary: #000000;
$black-second: #0a0a0a;
$black-third: #202020;
$black-primary-gradiend: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.96) 100%
);

$phone: 319px;
$phone-md: 350px;
$mobile: 575px;
$tablet: 767px;
$desktop: 991px;
$desktop-x: 1139px;
$desktop-xxl: 1399px;
